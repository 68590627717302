<template>
<div class="columns is-desktop is-centered">
  <div class="column is-four-fifths-fullhd">
    <card-component
  class="has-table has-mobile-sort-spaced is-flex-mobile"
 >
  
  <h1 class="title is-2" style="color: #111111; padding: 1em 1em 0em 1em;">ບໍລິສັດ ກຽງໄກຂົນສົ່ງດ່ວນ ຈຳກັດ</h1>
  <h3 class="title is-3" style="color: #111111; padding: 0em 1em 0em 1em;">ຂໍ້ກຳນົດ ເເລະ ເງື່ອນໄຂຂອງການຂົນສົ່ງ</h3>
  
  <section>

  <b-collapse
   class="card"
   animation="slide"
   v-for="(collapse, index) of collapses"
   :key="index"
   @open="isOpen = index">
   <template #trigger="props">
    <div class="card-header" role="button">
     <p class="card-header-title">
      {{ collapse.title }}
     </p>
     <a class="card-header-icon">
      <b-icon
       :icon="props.open ? 'menu-down' : 'menu-up'">
      </b-icon>
     </a>
    </div>
   </template>
   <div class="card-content">
    <div class="content">
     <ol style="padding: 0em 1em 1em 1em;">
      <template v-for="(item,index) in collapse.text" >
       <ul v-if="item.is_not_li" :key="index">{{ item.label }}</ul>
        <li v-else :key="index">{{ item.label }}</li>
      </template>
      
     </ol>
    </div>
   </div>
  </b-collapse>

 </section>
 </card-component>
  </div>
</div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
 
export default {
 name: "Branch",
 components: { CardComponent},
 data() {
 return {
  isOpen: 0,
    collapses: [
{title:`I. ອະທິບາຍຄຳສັບ`,
text:
[{label: `ຄຳສັບຕໍ່ໄປນີ້ແມ່ນຈະຖືກນຳໃຊ້ໃນຂໍ້ກຳນົດ ເເລະ ເງື່ອນໄຂ, ເຊີ່ງຈະມີຄວາມຫມາຍຕາມທີ່ກຳນົດໄວ້ດັ່ງຕໍ່ໄປນີ້:`
},{label: `“ເຮົາ”, “ພວກເຮົາ” ຫລື “ຂອງເຮົາ” ໝາຍເຖີງ ບໍລິສັດ ກຽງໄກຂົນສົ່ງດ່ວນ ຈຳກັດ, ພະນັກງານ ຫຼື ຕົວເເທນຂອງ ບໍລິສັດ ກຽງໄກຂົນສົ່ງດ່ວນ.`
},{label: `“ທ່ານ” ຫລື “ຂອງທ່ານ” ໝາຍເຖີງຜູ້ສົ່ງສິນຄ້າ, ຜູ້ສົ່ງເຄື່ອງ ເເລະ ມີຊື່ເປັນຜູ້ສົ່ງສິນຄ້າໃນໃບນຳສົ່ງສິນຄ້າ.`
},{label: `“ພັດສະດຸ”, “ສິນຄ້າ” ຫຼື “ເຄື່ອງ” ໝາຍເຖີງບັນຈຸພັນ, ກ່ອງ ຫຼື ສີ່ງຫຸ້ມຫໍ່ ທີ່ຖືກຮັບເອົາໂດຍພວກເຮົາເພື່ອການຂົນສົ່ງໄປອີກບ່ອນໜື່ງ.`
},{label: `“ຜູ້ສົ່ງ” ໝາຍເຖີງບຸກຄົນທີ່ນຳພັດສະດຸມາໃຫ້ພວກເຮົາເພື່ອຂົນສົ່ງ.`
},{label: `“ຜູ້ຮັບ” ໝາຍເຖີງຜູ້ທີ່ມາຮັບພັດສະດຸ ປາຍທາງ.`
},{label: `“ໃບນຳສົ່ງເຄື່ອງ” ຫຼື “ໃບນຳສົ່ງພັດສະດຸ” ໝາຍເຖີງໃບກຳກັບສົ່ງ ຫຼື ເອກະສານອື່ນທີ່ທຽບເທົ່າ ຫຼື ແບບເອເລັກໂທຼນິກທີ່ກ່ຽວຂ້ອງກັບພັດສະດຸ.`
},{label: `“ວັນທຳການ” ໝາຍເຖິງວັນຈັນເຖີງວັນເສົາ ບໍ່ລວມວັນຢຸດບຸນປະເພນີ ຫຼື ວັນພັກເເຫ່ງຊາດໃນປະຕິທິນເເຫ່ງ ສປປ ລາວ.`
},{label: `ການຍອມຮັບຂໍ້ກຳນົດ ເເລະ ເງື່ອນໄຂຂອງພວກເຮົາ`
},{label: `ເມື່ອໃດກໍ່ຕາມທີ່ທ່ານໃຊ້ບໍລິການຂອງເຮົາ ຫຼື ສົ່ງມອບພັດສະດຸໃຫ້ເຮົາ ຖືວ່າທ່ານ ເເລະ ບຸກຄົນທີ່ມີຊື່ກ່ຽວຂ້ອງກັບພັດສະດຸ ຍອມຮັບ ຂໍ້ກຳນົດ ແລະ ເງື່ອນໄຂ ໃນນາມ “ຜູ້ສົ່ງ” ແລະ “ຜູ້ຮັບ” ທັນທີ.`,
}]},
{title:`II. ການຫຸ້ມຫໍ່ພັດສະດຸທີ່ເໝາະສົມ`,
text:[
{ label:`ປະເພດຖົງຢາງປຼາສຕິກທີ່ປິດສະນິດ ຫຼື ຖົງພາສຕິກທີ່ມີກາວຕິດປາກຖົງທີ່ຕິດດີແລ້ວ.`
},{label:`ປະເພດກ໋ອງ/ແກັດເຈ້ຍ ທີ່ມີເຄື່ອງທີ່ອາດແຕກຫັກຢູ່ທາງໃນ ຕ້ອງໄດ້ຫຸ້ມດ້ວຍນວມ ຫຼື ໂຟມປ້ອງກັນການແຕກຫັກເປ່ເພກ່ອນບັນຈຸເຂົ້າກ໋ອງ/ແກັດດັ່ງກ່າວ.`
},{label:`ປະເພດເອກະສານ ຕ້ອງໄດ້ບັນຈຸໃນຊອງ ແລະ ຫຸ້ມດ້ວຍຖົງປຼາສຕິກປ້ອງການອ້າຍນ້ຳ ຫຼື ຄວາມຊຸ່ມທີ່ຈະເຮັດໃຫ້ເອກະສານເສຍຫາຍ.`
},{label:`ປະເພດອາຫານທີ່ບູດເນົ່າໄວ ຫຼື ມີການປ່ຽນຮູບຊົງເມື່ອອຸນຫະພຸມປ່ຽນແປງ ຕ້ອງໄດ້ບັນຈຸໃນກ່ອງໂຟມແລະວັດຖຸໃຫ້ຄວາມເຢັນແລ້ວປິດໃຫ້ແໜ້ນເພື່ອຫຼິກລ່ຽງຄວາມເສຍຫາຍ.`
},{label:`ປະເພດຂອງແຫຼວຕອ້ງໄດ້ບັນຈຸໃນກ໋ອງທີ່ຮັບປະກັນບໍໃຫ້ແຕກຫຼືຮົ່ວໄຫຼ.`
 }]},
{title:`III. ພັດສະດຸຕ້ອງຫ້າມ`,
text: 
[{label:`ສິນຄ້າຜິດກົດໝາຍ ຫຼື ຖືກຫ້າມຕາມກົດຫມາຍ ຂອງ ສປປ ລາວ.`
},{label:`ວັດຖຸອັນຕະລາຍລວມເຖີງສິນຄ້າສ່ຽງອັນຕະລາຍຫລຶເປັນພິດ, ສີ່ງທີ່ອາດເກີດລະເບີດ ເວລາມີການເຄື່ອນຍ້າຍບໍ່ຖືກວິທີ, ວັດຖຸລະເບີດ ຫຼື ອາວຸດສົງຄາມ.`
},{label:`ໝໍ້ໄຟລົດທຸກຊະນິດທີ່ຖືກໃຊ້ງານເເລ້ວ.`
},{label:`ທະນະບັດ ເງິນສົດ ເເລະ ເອກະສານທຽບເທົ່າ.`
},{label:`ບັດເຄຼດິດ ບັດເດບິດ ຫຼື ບັດເງິນສົດ.`
},{label:`ເອກະສານສ່ວນບຸກຄົນທີ່ອອກໂດຍຫນ່ວຍງານລັດທະບານ ເຊັ່ນ ບັດປະຈຳຕົວ, ໜັງສືຜ່ານເເດນ ຫຼື ໃບຂັບຂີ່ເປັນຕົ້ນ.`
},{label:`ເອກະສານທີ່ມີຂໍ້ມູນເປັນຄວາມລັບ.`
},{label:`ເພັດ, ຄຳເເທ່ງ ຫຼື ສິນຄ້າມີມູນຄ່າສູງ.`
},{label:`ວັດຖຸສະສົມ, ວັດຖຸບູຮານ ຫຼື ງານສິນລະປະທີ່ມິຄຸນຄ່າອັນປະເມີນໄດ້ຫຍາກ.`
},{label:`ຊາກ, ຊີ້ນສ່ວນ ຫຼື ອະໄວຍະວະ ຂອງມະນຸດ.`
},{label:`ອາຫານສົດ, ອາຫານດິບ, ເຄື່ອງທີ່ສົ່ງກິນແຮງ ທີ່ບໍ່ໄດ້ຫຸ້ມຫໍ່ ຫຼື ບັນຈຸທີ່ເໝາະສົມພຽງພໍ.`
},{label:`ພັດສະດຸທີ່ຕ້ອງໄດ້ຮັບອະນຸຍາດພິເສດ ຫຼື ຕ້ອງມີໃບອະນຸຍາດສຳລັບການຂົນສົ່ງ.`
},{label:`ພັດສະດຸທີ່ຕ້ອງມີການຮັບຜິດຊອບຕໍ່ຄວາມສູນເສຍ ໃນການສົ່ງ ຫຼື ມອບຮັບລ້າຊ້າ.`
},{label:`ພັດສະດຸທີ່ບໍ່ສາມາດປະເມີນມູນຄ່າໄດ້.`
 }]},
{title:`IV. ການຮັບປະກັນ ເເລະ ການຊົດໃຊ້ຄ່າເສຍຫາຍ.`,
text: 
[{label:`ກໍລະນີທີ່ຈະບໍ່ຖືກຊົດໃຊ້ຄືນຄ່າເສຍຫາຍ.`
},{is_not_li:true,label:`§ ເຄື່ອງຕ້ອງຫ້າມທີ່ຖືກກຳນົດໄວ້.`
},{is_not_li:true,label:`§ ກໍລະນີເຫດສຸດວິໃສ, ໄພທຳມະຊາດ, ສົງຄາມ, ເສຍຫາຍຈາກພາກສ່ວນອື່ນທີ່ບໍ່ຢູ່ພາຍໃຕ້ຄວາມຮັບຜິດຊອບຂອງບໍລິສັດ.`
},{is_not_li:true,label:`§ ກໍລະນີມີການແຕກຫັກເປ່ເພ ອັນເນື່ອງມາຈາກການຫຸ້ມຫໍ່ບໍ່ຮັບປະກັນຂອງຜູ້ສົ່ງ.`
},{is_not_li:true,label:`§ ອາຫານ, ເຄື່ອງ ບູດເໜົ່າໃນລະຫວ່າງການຂົນສົ່ງ.`
},{is_not_li:true,label:`§ ສິນຄ້າເປື່ອຍເລະບໍ່ຢູ່ໃນຮູບຊົງເກົ່າ ອັນເນື່ອງຈາກອຸນຫະພຸມ, ແຕ່ຜູ້ສົ່ງບໍ່ໄດ້ບັນຈຸ ໃນບັນຈຸພັນທີ່ເໝາະສົມ.`
},{is_not_li:true,label:`§ ເອກະສານ, ໃບຮັບເງີນ ຫຼື ໃບແຈ້ງໜີ້ ຕ່າງໆ.`
},{is_not_li:true,label:`§ ຜູ້ສົ່ງສິນຄ້າບໍ່ປະຕິບັດຕາມລະບຽບ ແລະ ຄຳແນະນຳໃນການບັນຈຸສິນຄ້າ.`
},{label:`ກໍລະນີທີ່ຈະໄດ້ຮັບການຊົດໃຊ້ຄືນ`
},{is_not_li:true,label:`§ ເຄື່ອງເສຍຫາຍຈາກຂົນຍ້າຍຂອງ ພະນັກງານ ບໍລິສັດ.`
},{is_not_li:true,label:`§ ເຄື່ອງສູນເສຍໃນລະຫວ່າງການຂົນສົ່ງ ໂດຍທີ່ບໍທັນໄດ້ມອບໃຫ້ຜູ້ຮັບເຄື່ອງປາຍທາງ.`
 }]},
{title:`V. ການຈັດສົ່ງພັດສະດຸ`,
text: 
[{label:`ການຈັດສົ່ງຈະຖືວ່າສີ້ນສຸດເມື່ອພັດສະດຸຖືກ ຮັບຈາກຜູ້ຮັບທີ່ລະບຸໄວ້ ຫຼື ສົ່ງໄປຍັງທີ່ຢູ່ຂອງຜູ້ຮັບ ຫຼື ຖືກຮັບຈາກຜູ້ຕາງຂອງຜູ້ຮັບໂດຍການຢັ້ງຢືນເອກະສານທີ່ກ່ຽວຂ້ອງ.`
},{label:`ພັດສະດຸບໍ່ສາມາດສົ່ງໄປຍັງຕູ້ໄປສະນີ ຫຼື ລະຫັດໄປສະນີ.`
},{label:`ເຮົາຈະບໍ່ສົ່ງພັດສະດຸໄປຍັງທີ່ຢູ່ອື່ນນອກເໜືອຈາກທີ່ຢູ່ຂອງຜູ້ຮັບມອບຊື່ງລະບຸໄວ້ໃນໃບນຳສົ່ງສິນຄ້າ ເວັ້ນເສຍເເຕ່ຈະໄດ້ຮັບຄຳເເນະນຳຈາກຜູ້ສົ່ງສິນຄ້າ ແລະ ຜູ້ຮັບຈະຕ້ອງຕິດຕໍ່ຫາຜູ້ສົ່ງສິນຄ້າ ເພື່ອຂໍຂໍ້ມູນດັ່ງກ່າວ.`
 }]},
{title:`VI. ການຈັດສົ່ງບໍ່ສຳເລັດ`,
text:
[{label:`ຜູ້ຮັບປະຕິເສດການຈັດສົ່ງຫຼື ຈ່າຍເງິນເກັບປາຍທາງ`
},{label:`ບໍ່ສາມາດລະບຸ ຫຼື ບອກຕຳເເຫນ່ງຂອງຜູ້ຮັບໄດ້.`
},{label:`ບໍ່ມີຜູ້ມາຮັບພັດສະດຸ.`
 }]},
{title:`VII. ພັດສະດຸທີ່ບໍ່ໄດ້ສົ່ງມອບ ເເລະ ການສົ່ງຄືນ.`,
text: 
[{label:`ການຈັດສົ່ງບໍ່ສຳເລັດ`
},{label:`ບໍ່ສາມາດຈັດສົ່ງພັດສະດຸໄດ້ພາຍໃນ 7 ວັນທຳການຕາມປະມານວັນທີ່ຈັດສົ່ງ.`
},{label:`ຜູ້ສົ່ງສິນຄ້າບໍ່ສາມາດໃຫ້ຄຳເເນະນຳເພີ່ມເຕີມຕາມຄຳຂໍຂອງເຮົາ.`
},{label:`ຜູ້ສົ່ງສິນຄ້າຂໍຍົກເລີກການຈັດສົ່ງເເລະ / ຫລືສົ່ງຄືນພັດສະດຸດ້ວຍເຫດຜົນໃດໆກໍ່ຕາມ.`
},{label:`ພັດສະດຸທີ່ບໍ່ໄດ້ສົ່ງມອບຈະຖືກສົ່ງກັບໄປໃຫ້ຜູ້ສົ່ງສິນຄ້າໂດຍຜູ້ສົ່ງສິນຄ້າຈະເປັນຜູ້ຮັບຄ່າໃຊ້ຈ່າຍ ຫາກບໍ່ມີຜູ້ຮັບເຮົາຈະທຳການນຳພັດສະດຸ ຈຳຫນ່າຍ ຫຼື ຂາຍໂດຍບໍ່ຕ້ອງຮັບຜິດໃດໆ ຕໍ່ຜູ້ສົ່ງສິນຄ້າ ຫຼື ບຸກຄົນອື່ນໆ.`
},{label:`ບໍ່ສາມາດຂໍຄືນຄ່າສົ່ງ ເເລະ ຄ່າໃຊ້ຈ່າຍອື່ນທີ່ຈ່າຍເພື່ອສົ່ງພັດສະດຸໄດ້.`
 }]},
{title:`VIII. ຄ່າຈັດສົ່ງ ເເລະ ຄ່າໃຊ້ຈ່າຍອື່ນໆ`,
text: [
{label:`ຜູ້ສົ່ງຈະຕ້ອງເປັນຜູ້ຊຳລະຄ່າສົ່ງ ກໍລະນີບໍແມ່ນເກັບຄ່າສົ່ງປາຍທາງ.`}
,{label:`ນອກຈາກນີ້ຍັງມີຄ່າໃຊ້ຈ່າຍອື່ນໆເຊັ່ນ ຄ່າປະກັນໄພ ຄ່າຄືນພັດສະດຸ ຄ່າບໍລິການດ່ວນພິເສດ ເເລະອື່ນໆ (ຖ້າມີ) ໃນການຈັດສົ່ງພັດສະດຸ ຄ່າໃຊ້ຈ່າຍທັ້ງຫມົດອາດມີການປ່ຽນເເປງໄດ້ໂດຍບໍ່ຕ້ອງເເຈ້ງໃຫ້ຊາບລ່ວງຫນ້າ.`
}]},
{title:`IX. ບໍລິການເກັບເງິນປາຍທາງ(COD) ເເລະ ຄ່າບໍລິການເກັບເງິນປາຍທາງ.`,
text: 
[{label:`ຫາກທ່ານເລືອກບໍລິການເກັບເງິນປາຍທາງຜູ້ສົ່ງສິນຄ້າ ຈະຕ້ອງຊຳລະຄ່າບໍລິການເກັບເງິນປາຍທາງ ຈຳນວນເງິນສູງສຸດທີ່ເຮົາສາມາດເອີ້ນເກັບປາຍທາງໄດ້ຄື 2,000,000ລ້ານກີບ ຕໍ່ 1 ໃບນຳສົ່ງສິນຄ້າ.`
},{label:`ເຮົາບໍ່ໄດ້ເປັນໂຕເເທນຂອງຜູ້ສົ່ງສິນຄ້າໃນບໍລິການເກັບເງີນປາຍທາງ ຫຼື ກໍ່ໃຫ້ເກີດພາລະຜູກພັນເຮົາກັບຜູ້ຮັບ.`
},{label:`ຜູ້ສົ່ງສິນຄ້າຍອມຮັບຄວາມສ່ຽງທັ້ງຫມົດທີ່ກ່ຽວຂ້ອງກັບການຊຳລະເງິນ ລວມທັ້ງການບໍ່ຊຳລະເງິນ ເງິນບໍ່ພຽງພໍຫລືການປອມເເປງ.`
 }]},
{title:` X. ການເເກ້ໄຂທີ່ຢູ່ ເເລະ ການກຳຫນົດເສັ້ນທາງໃຫມ່`,
text:
[{label:`ຫາກຜູ້ສົ່ງສິນຄ້າຕ້ອງປ່ຽນປາຍທາງຂອງຜູ້ຮັບໃໝ່ທີ່ບໍ່ຢູ່ໃນຈຸດປາຍທາງເກົ່າ ຜູ້ສົ່ງຕ້ອງໄດ້ເສຍຄ່າສົ່ງເພີ່ມເຕີມ, ເວັ້ນເສຍແຕ່ຜູ້ຮັບເປັນຄົນຈ່າຍຄ່າສົ່ງ.`
},{label:`ກຳຫນົດທີ່ຢູ່ຂອງຜູ້ຮັບໃຫມ່ ກໍລະນີສົ່ງເຄື່ອງໄປທີ່ຢູ່ຂອງຜູ້ຮັບແມ່ນຈະຕ້ອງຊຳລະຄ່າບໍລິການເພີ່ມເຕີມ.`,
 }]},
{title:`XI. ສິດທິໃນການກວດສອບ`,
text: 
[{label:`ເຮົາຂໍສະຫງວນສິດໃນການເປີດເເລະກວດສອບພັດສະດຸໂດຍບໍ່ຕ້ອງເເຈ້ງໃຫ້ທ່ານຊາບເພື່ອຄວາມປອດໄພ ຫຼື ຕາມການ່ໍຮ້ອງຂໍຂອງເຈົ້າໜ້າທີ່ພາສີອາກອນ ຫຼື ຕາມກົດລະບຽບ.`
},{label:`ໃນກໍລະນີເຈົ້າໜ້າທີ່ຂອງລັດອາດຕ້ອງເປີດ ເເລະ ກວດສອບພັດສະດຸເຮົາຈຳເປັນຕ້ອງປະຕິບັດຕາມຄຳເເນະນຳດັ່ງກ່າວຢ່າງເຄັ່ງຄັດ ໂດຍບໍ່ຈໍາເປັນຕ້ອງເເຈ້ງໃຫ້ຜູ້ສົ່ງສິນຄ້າ ຫຼື ຜູ້ຮັບ ໃຫ້ຮັບຮູ້ລ່ວງຫນ້າ.`,
 }]},
{title:`XII. ຄວາມຮັບຜິດຊອບຂອງເຮົາ`,
text:
[{label:`ຄວາມຮັບຜິດຊອບໃນພັດສະດຸໃດໆ ນັ້ນຈະຈໍາກັດພຽງການສູນເສຍ ເເລະ ຄວາມເສຍຫາຍໂດຍກົງ ເເລະ ລວມມູນຄ່າສູງສຸດບໍ່ເກີນ 500,000ກີບ ຕໍ່ 1 ໃບນຳສົ່ງສິນຄ້າເວັ້ນເສຍເເຕ່ວ່າມີການລະບຸໄວ້ໃນຂໍ້ຕົກລົງຮ່ວມກັນເປັນລາຍລັກອັກສອນລະຫວ່າງຜູ້ສົ່ງສິນຄ້າ ເເລະ ເຮົາ.`
},{label:`ພວເຮົາຈະບໍ່ຮັບຜິດຊອບຕໍ່ ການສູນຫາຍ ຫຼື ຄວາມເສຍຫາຍຕໍ່ພັດສະດຸຕ້ອງຫ້າມ ຈາກການກັກຢຶດຂອງເຈົ້າໜ້າທີ່.`
},{label:`ພວເຮົາຈະບໍ່ຮັບຜິດຊອບຕໍ່ ການສູນຫາຍ ຫຼື ຄວາມເສຍຫາຍທີ່ເກີດຂື້ນຈາກການມາຮັບພັດສະດຸລ່າຊ້າຂອງຜູ້ຮັບ.`
},{label:`ພວເຮົາຈະບໍ່ຮັບຜິດຊອບຕໍ່ ການຜິດພາດຂອງບໍລິສັດຂົນສົ່ງອື່ນທີ່ຮັບຕໍ່ຈາກເຮົາ`
},{label:`ສິນຄ້າທີ່ຄ້າງສາງເກີນ 14 ວັນເຮົາຈະບໍ່ຮັບຜິດຊອບກັບຄວາມເສຍຫາຍໃດໆທີ່ເກີດຂຶ້ນ.`
},{label:`ຜູ້ສົ່ງສາມາດນຳຄືນ ຫຼື ທວງຖາມລາຍລະອຽດພັດສະດຸຍ້ອນຫຼັງຄືນໄດ້ບໍ່ເກີນ 30 ວັນຫຼັງສົ່ງມອບພັດສະດຸໃຫ້ເຮົາ.`
 }]},
{title:`XIII. ສະຖານະການນອກເຫນືອການຄວມຄຸມຂອງເຮົາ`,
text: 
[{label:`ຄວາມເສຍຫາຍຂອງຂໍ້ມູນເນື່ອງຈາກຜົນກະທົບຈາກສະຖານະການເເມ່ເຫຼັກຂອງພັດສະດຸອື່ນ.`
},{label:`ການກະທຳ ຫຼື ການລະເລີຍໂດຍບຸກຄົນທີ່ບໍ່ໄດ້ເຮັດວຽກເຮັດສັນຍາກັບເຮົາ ເຊັ່ນ ຜູ້ສົ່ງສິນຄ້າ, ຜູ້ຮັບ, ບຸກຄົນທີ່ສາມ ຫຼື ເຈົ້າໜ້າທີ່ລັດ.`
},{label:`ເຫດສຸດວິໃສ ເຊັ່ນ ສົງຄາມ (ທັ້ງທີ່ປະກາດຫລືບໍ່ປະກາດຢ່າງເປັນທາງການ) ເຫດລະເບີດ ການໃຊ້ອາວຸດສົງຄາມ ລວມທັ້ງການຍືດພັດສະດຸ ການນັດຢຸດງານຂອງພະນັກງານ ເຫດສຸດວິໃສ(ເຊັ່ນ ນ້ຳຖ້ວມ ເເຜ່ນດິນໄຫວ ພາຍຸ ໆລໆ).`,
 }]},
{title:`XIV. ຂໍ້ບັງຄັບໃຊ້`,
text:[{label:`ຂໍ້ກຳນົດ ເເລະ ເງືອນໄຂນີ້ຢູ່ພາຍໃຕ້ກົດຫມາຍເເຫ່ງ ສປປ ລາວ ເເລະຄູ່ສັນຍາທັ້ງສອງຝ່າຍຢູ່ພາຍໃຕ້ເຂດອຳນາດສານເເຫ່ງ ສປປ ລາວ ໂດຍບໍ່ອາດເຜີກເສີຍໄດ້.`,}]
}
]
 };
 },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>



